var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main text-center" },
    [
      _c("h3", { staticClass: "mt-5" }, [
        _vm._v("受付情報の事前登録が完了しました")
      ]),
      _c("p", { staticClass: "paragraph" }, [
        _vm._v("避難所へお越しの際、下記QRコードを受付職員にご提示下さい")
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _vm.qrcode_data
            ? _c("vue-qrcode", {
                staticClass: "my-4",
                attrs: {
                  id: "qrcode",
                  value: _vm.qrcode_data,
                  options: _vm.qr_options,
                  tag: "img"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("p", { staticClass: "small" }, [
        _c("b", [_vm._v("No. " + _vm._s(_vm.qrcode_data))])
      ]),
      _vm._m(0),
      _c(
        "b-button",
        {
          staticClass: "mt-3",
          attrs: { variant: "dark", size: "lg" },
          on: { click: _vm.download }
        },
        [_vm._v("ダウンロード")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph small" }, [
      _vm._v("このQRコードは避難所を退場するまで利用します"),
      _c("br"),
      _vm._v("この画面をスクリーンショットで撮るか、"),
      _c("br"),
      _vm._v("下記のダウンロードボタンを押して端末に保存しておいてください")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }