<template>
  <div class="main text-center">
    <h3 class="mt-5">受付情報の事前登録が完了しました</h3>
    <p class="paragraph">避難所へお越しの際、下記QRコードを受付職員にご提示下さい</p>
    <div class="mt-5">
      <vue-qrcode id="qrcode" class="my-4" v-if="qrcode_data" :value="qrcode_data" :options="qr_options" tag="img" />
    </div>
    <p class="small"><b>No. {{ qrcode_data }}</b></p>
    <p class="paragraph small">このQRコードは避難所を退場するまで利用します<br>この画面をスクリーンショットで撮るか、<br>下記のダウンロードボタンを押して端末に保存しておいてください</p>
    <b-button variant="dark" @click="download" class="mt-3" size="lg">ダウンロード</b-button>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  data() {
    return {
      qrcode_data: this.$route.params.qrcode_data,
      qr_options: {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        margin: 1,
        scale: 8,
        color: {
          dark: "#000000FF",
          light: "#FFFFFFFF",
        },
      },
    };
  },
  components: {
    VueQrcode,
  },
  mounted() {
    this.validate()
  },
  methods: {
    validate() {
      if (!this.qrcode_data) {
        this.$router.push({ name: 'not_found'})
      }
    },
    download() {
      let link = document.createElement("a");
      link.href = document.getElementById("qrcode").src;
      link.download = `${this.qrcode_data}.png`;
      link.click();
    },
  },
};
</script>

<style scoped>
.main {
  margin: 20px;
}
.paragraph {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}
</style>
